// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul{
    padding: 0;
    margin: 0;
}

.navbar-container{
    text-align: center;
    justify-content: center;
    gap: 10px;
}
.navbar {
    display: flex;
    position: absolute; /* Change to fixed for persistent placement */
    top: 35px; /* Align it to the top */
    width: 100%; /* Full width of the viewport */
    text-align: center; /* Center align the content inside .navbar */
    justify-content: center;
    z-index: 1000; /* Ensure it is on top */
    gap: 20px;
}

.navbar ul {
    display: flex; /* Use flexbox for the list items */
    margin: 0;
    padding: 0;
    list-style: none; /* Remove default list styling */
}


.navbar li {
    display: inline-block;
}

.navbar .nav-link {
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: black;
}
.navbar .nav-link:hover {
    color: rgb(0, 102, 255);
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,aAAa;IACb,kBAAkB,EAAE,6CAA6C;IACjE,SAAS,EAAE,wBAAwB;IACnC,WAAW,EAAE,+BAA+B;IAC5C,kBAAkB,EAAE,4CAA4C;IAChE,uBAAuB;IACvB,aAAa,EAAE,wBAAwB;IACvC,SAAS;AACb;;AAEA;IACI,aAAa,EAAE,mCAAmC;IAClD,SAAS;IACT,UAAU;IACV,gBAAgB,EAAE,gCAAgC;AACtD;;;AAGA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,qBAAqB;IACrB,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,eAAe;IACf,YAAY;AAChB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":["ul{\n    padding: 0;\n    margin: 0;\n}\n\n.navbar-container{\n    text-align: center;\n    justify-content: center;\n    gap: 10px;\n}\n.navbar {\n    display: flex;\n    position: absolute; /* Change to fixed for persistent placement */\n    top: 35px; /* Align it to the top */\n    width: 100%; /* Full width of the viewport */\n    text-align: center; /* Center align the content inside .navbar */\n    justify-content: center;\n    z-index: 1000; /* Ensure it is on top */\n    gap: 20px;\n}\n\n.navbar ul {\n    display: flex; /* Use flexbox for the list items */\n    margin: 0;\n    padding: 0;\n    list-style: none; /* Remove default list styling */\n}\n\n\n.navbar li {\n    display: inline-block;\n}\n\n.navbar .nav-link {\n    text-decoration: none;\n    font-weight: bold;\n    display: inline-block;\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    font-size: 16px;\n    color: black;\n}\n.navbar .nav-link:hover {\n    color: rgb(0, 102, 255);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
