// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100px);
  }
}

.message-bubble {
  position: fixed;
  bottom: 60px; /* Adjust as needed */
  right: 115px; /* Adjust as needed to align with the chat icon */
  padding: 10px 20px;
  background-color: #292929;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateX(95px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  z-index: 1000;
  quotes: "“" "”" "‘" "’";
  pointer-events: none;
}

.message-bubble.visible {
  opacity: 1;
  transform: translateX(0);
}

.message-bubble.fade-out {
  animation: fadeOutRight 1s forwards;
}

.message-bubble:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-left-color: #292929;
  border-right: 0;
  border-top: 0;
  margin-right: -8px;
}`, "",{"version":3,"sources":["webpack://./src/components/MessageBubble.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,4BAA4B;EAC9B;AACF;;AAEA;EACE,eAAe;EACf,YAAY,EAAE,qBAAqB;EACnC,YAAY,EAAE,iDAAiD;EAC/D,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,mBAAmB;EACnB,yCAAyC;EACzC,UAAU;EACV,2BAA2B;EAC3B,kDAAkD;EAClD,aAAa;EACb,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,0BAA0B;EAC1B,eAAe;EACf,aAAa;EACb,kBAAkB;AACpB","sourcesContent":["@keyframes fadeOutRight {\r\n  0% {\r\n    opacity: 1;\r\n    transform: translateX(0);\r\n  }\r\n  100% {\r\n    opacity: 0;\r\n    transform: translateX(100px);\r\n  }\r\n}\r\n\r\n.message-bubble {\r\n  position: fixed;\r\n  bottom: 60px; /* Adjust as needed */\r\n  right: 115px; /* Adjust as needed to align with the chat icon */\r\n  padding: 10px 20px;\r\n  background-color: #292929;\r\n  color: #fff;\r\n  border-radius: 20px;\r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);\r\n  opacity: 0;\r\n  transform: translateX(95px);\r\n  transition: opacity 0.5s ease, transform 0.5s ease;\r\n  z-index: 1000;\r\n  quotes: \"“\" \"”\" \"‘\" \"’\";\r\n  pointer-events: none;\r\n}\r\n\r\n.message-bubble.visible {\r\n  opacity: 1;\r\n  transform: translateX(0);\r\n}\r\n\r\n.message-bubble.fade-out {\r\n  animation: fadeOutRight 1s forwards;\r\n}\r\n\r\n.message-bubble:after {\r\n  content: '';\r\n  position: absolute;\r\n  right: 0;\r\n  top: 50%;\r\n  width: 0;\r\n  height: 0;\r\n  border: 12px solid transparent;\r\n  border-left-color: #292929;\r\n  border-right: 0;\r\n  border-top: 0;\r\n  margin-right: -8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
