// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects-container {
    margin-top: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 20px; 
    width: 100%;
}

.project-boxes {
    display: flex;
    flex-wrap: wrap; 
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
}

.project-box {
    background-color: #292929;
    border-radius: 10px;
    box-shadow: 10px 10px 15px rgb(54, 54, 54);
    color: #fff;
    cursor: pointer;
    flex: 1 1 300px; 
    max-width: 300px; 
    height: 200px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 10px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.project-box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}

.project-box h2 {
    font-size: 1.5rem;
    margin: 10px;
}

.project-box p {
    font-size: 1rem;
    margin: 15px 10px 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1200px) {
    .project-box {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .project-box {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(100% - 20px); 
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Projects.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,0CAA0C;IAC1C,WAAW;IACX,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,qDAAqD;IACrD,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,2CAA2C;AAC/C;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,sBAAsB;IACtB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI;QACI,YAA0B;QAA1B,cAA0B;QAA1B,4BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,YAA2B;QAA3B,cAA2B;QAA3B,6BAA2B;IAC/B;AACJ","sourcesContent":[".projects-container {\n    margin-top: 150px;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center; \n    gap: 20px; \n    width: 100%;\n}\n\n.project-boxes {\n    display: flex;\n    flex-wrap: wrap; \n    gap: 10px;\n    justify-content: center;\n    margin-bottom: 20px;\n}\n\n.project-box {\n    background-color: #292929;\n    border-radius: 10px;\n    box-shadow: 10px 10px 15px rgb(54, 54, 54);\n    color: #fff;\n    cursor: pointer;\n    flex: 1 1 300px; \n    max-width: 300px; \n    height: 200px;\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n    margin: 10px; \n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    overflow: hidden;\n}\n\n.project-box:hover {\n    transform: translateY(-5px);\n    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);\n}\n\n.project-box h2 {\n    font-size: 1.5rem;\n    margin: 10px;\n}\n\n.project-box p {\n    font-size: 1rem;\n    margin: 15px 10px 50px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n@media (max-width: 1200px) {\n    .project-box {\n        flex: 1 1 calc(50% - 20px);\n    }\n}\n\n@media (max-width: 768px) {\n    .project-box {\n        flex: 1 1 calc(100% - 20px); \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
