// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/art.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: 100%;
  width: 100%;
}

body {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

.wrapper {
  min-height: 110vh; 
  overflow-y: scroll; 
}

body::-webkit-scrollbar {
  width: 10px; 
}

body::-webkit-scrollbar-track {
  background-color: #333; 
}

body::-webkit-scrollbar-thumb {
  background-color: #000; 
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,yDAAyC;EACzC,sBAAsB;EACtB,4BAA4B;EAC5B,4BAA4B;EAC5B,iCAAiC;EACjC,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["html, body {\n  height: 100%;\n  width: 100%;\n}\n\nbody {\n  background-image: url('./images/art.jpg');\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-attachment: fixed;\n  font-family: 'Roboto', sans-serif;\n  margin: 0;\n  padding: 0;\n  overflow-y: scroll;\n}\n\n.wrapper {\n  min-height: 110vh; \n  overflow-y: scroll; \n}\n\nbody::-webkit-scrollbar {\n  width: 10px; \n}\n\nbody::-webkit-scrollbar-track {\n  background-color: #333; \n}\n\nbody::-webkit-scrollbar-thumb {\n  background-color: #000; \n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
