// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResumePDF_resumePdf__ixBVs {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.ResumePDF_pdfViewer__q0rja {
    width: 100%;
    height: 100%;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ResumePDF.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".resumePdf {\n    width: 100%;\n    height: 100vh;\n    overflow: hidden;\n}\n\n.pdfViewer {\n    width: 100%;\n    height: 100%;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resumePdf": `ResumePDF_resumePdf__ixBVs`,
	"pdfViewer": `ResumePDF_pdfViewer__q0rja`
};
export default ___CSS_LOADER_EXPORT___;
